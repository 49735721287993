<template>
  <div v-if="$can('read', abilityRequired)">
    <b-button
      variant="primary"
      size="lg"
      @click="launchElearning"
    >
      Launch E-Learning Site
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  setup() {
    const abilityRequired = 'elearning'
    const externalUrl = 'https://lms.udutu.ca/LMSPortal/Account/Logon?orgCode=CanCoSec'

    return {
      abilityRequired,
      externalUrl,
    }
  },

  methods: {
    launchElearning() {
      window.open(this.externalUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.iframe-container {
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
